import './App.css';

function App() {
  const currentPath = window.location.pathname;
  var all = process.env.REACT_APP_DOMAIN+currentPath;
  return (
    <div className="container">
         <img src= {all} />
    </div>
  );
}

export default App;